import { Injectable, inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CookieService, LocalstorageService, PlatformService } from 'ngx-unificator/services';
import { Router, UrlSerializer } from '@angular/router';
import { WINDOW } from 'ngx-unificator/tokens';
import { Location } from '@angular/common';
import { CommonDataService } from './common-data.service';

/**
 * Payment mode
 */
export enum PaymentMode {
  CRYPTO = 'crypto',
  FIAT = 'fiat',
}

@Injectable({
  providedIn: 'root'
})
export class UserPaymentModeService {
  private _cookie = inject(CookieService);
  private _router = inject(Router);
  private _serializer = inject(UrlSerializer);
  private _platform = inject(PlatformService);
  private _location = inject(Location);
  private _localStorage = inject(LocalstorageService);
  private _commonData = inject(CommonDataService);
  private _window = inject<Window>(WINDOW);


  private _currentMode: string = PaymentMode.FIAT;

  /**
   * Is mode crypto
   */
  public isModeCrypto$: ReplaySubject<boolean> = new ReplaySubject(1);

  /**
   * Current mode
   */
  public currentMode$: ReplaySubject<string> = new ReplaySubject(1);

  public get currentMode(): string {
    return this._currentMode;
  }

  public get isModeCrypto(): boolean {
    return this._currentMode === PaymentMode.CRYPTO;
  }

  /**
   * Check if partners link contain ?fm(fiat mode) param in url
   * fm - current mode fiat
   * default - current mode crypto
   * @returns
   */
  public resolveModeFromUrl() {
    if (!this._platform.isBrowser) {
      return;
    }
    const params = this._serializer.parse(this._window.location.search).queryParams;
    const domain = this._window.location.hostname.split('.').slice(-2).join('.');
    if (params.fm) {
      this._currentMode = PaymentMode.FIAT;
      this._cookie.set('MODE', PaymentMode.FIAT, 999, '/', domain);
      this._localStorage.set('MODE', PaymentMode.FIAT);
      delete params.fm;
    } else {
      if (this._cookie.check('MODE')) {
        this.changePaymentMode(this._cookie.get('MODE') as PaymentMode);
      } else {
        this.changePaymentMode(PaymentMode.FIAT);
      }
    }
    this.currentMode$.next(this._currentMode);
    const tree = this._router.parseUrl(
      this._location.normalize(window.location.pathname),
    );
    tree.queryParams = params;
    this._router.navigateByUrl(tree);
  }

  /**
   * Change fiat\crypto mode
   * @param mode
   * @returns
   */
  public changePaymentMode(mode: PaymentMode = null) {
    if (!this._platform.isBrowser) {
      return;
    }

    if (mode) {
      const domain = this._window.location.hostname.split('.').slice(-2).join('.');
      this._currentMode = mode;
      this._cookie.set('MODE', this._currentMode, 999, '/', domain);
      this._localStorage.set('MODE', this._currentMode);
    } else {
      const domain = this._window.location.hostname.split('.').slice(-2).join('.');
      this.isModeCrypto ? this._currentMode = PaymentMode.FIAT : this._currentMode = PaymentMode.CRYPTO;
      this._cookie.set('MODE', this._currentMode, 999, '/', domain);
      this._localStorage.set('MODE', this._currentMode);
    }
    this.currentMode$.next(this._currentMode);
    this.isModeCrypto$.next(this._currentMode === PaymentMode.CRYPTO);
  }

  /**
   * Resolve payment mode on user change currency
   * @param currency
   */
  public resolvePaymentModeOnCurrenyChange(currency: string) {
    if (currency && !this._window.location.pathname.includes('deposit') && !this._window.location.pathname.includes('cashout')) {
      this._commonData.isCryptoCurrency(currency) ? this.changePaymentMode(PaymentMode.CRYPTO) : this.changePaymentMode(PaymentMode.FIAT);
    }
  }

}
